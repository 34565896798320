import { Error } from './Error';

export function ClientError({ error }: { error: Error & { digest?: string } }) {
  return (
    <Error error={error}>
      <p className="mb-2 text-lg font-semibold text-white">
        Sorry, we couldn&apos;t find that page
      </p>
    </Error>
  );
}
