'use client'; // Error components must be Client Components

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import bgImage from '/public/images/error/error-background.jpeg';

import { Container } from '../../helpers/types';

export function Error({
  error,
  children,
}: Container & { error: Error & { digest?: string } }) {
  useEffect(() => {
    // TODO: Log the error to an error reporting service
  }, [error]);

  const router = useRouter();

  return (
    <div className="z-0 m-auto flex h-screen w-[352px] flex-col items-center justify-center text-center">
      <Image
        src={bgImage}
        alt=""
        className="absolute z-[-1] h-full w-full object-cover grayscale"
      />
      <div className="absolute z-[-1] h-full w-full bg-[radial-gradient(closest-side,rgba(2,2,3,0.85)_25%,rgba(2,2,3,1)_115%)]" />

      <ExclamationTriangleIcon className="mb-8 h-10 w-10 text-yellow" />
      {children}
      <div className="mt-8 flex gap-2">
        <button
          onClick={() => router.back()}
          className="flex items-center gap-1 rounded-lg px-4 py-2 text-primary hover:bg-green-muted"
        >
          Back
        </button>
        <button
          className="flex items-center gap-1 rounded-lg bg-green px-4 py-2 text-primary hover:bg-green hover:bg-opacity-80"
          onClick={() => (window.location.href = '/share/overview')}
        >
          Home
        </button>
      </div>
    </div>
  );
}
